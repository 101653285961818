exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-bhiravafire-js": () => import("./../../../src/pages/bhiravafire.js" /* webpackChunkName: "component---src-pages-bhiravafire-js" */),
  "component---src-pages-chandifire-js": () => import("./../../../src/pages/chandifire.js" /* webpackChunkName: "component---src-pages-chandifire-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-dhanvantryfire-js": () => import("./../../../src/pages/dhanvantryfire.js" /* webpackChunkName: "component---src-pages-dhanvantryfire-js" */),
  "component---src-pages-durgafire-js": () => import("./../../../src/pages/durgafire.js" /* webpackChunkName: "component---src-pages-durgafire-js" */),
  "component---src-pages-ganeshafire-js": () => import("./../../../src/pages/ganeshafire.js" /* webpackChunkName: "component---src-pages-ganeshafire-js" */),
  "component---src-pages-hanumamfire-js": () => import("./../../../src/pages/hanumamfire.js" /* webpackChunkName: "component---src-pages-hanumamfire-js" */),
  "component---src-pages-homam-js": () => import("./../../../src/pages/homam.js" /* webpackChunkName: "component---src-pages-homam-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jupiterfire-js": () => import("./../../../src/pages/jupiterfire.js" /* webpackChunkName: "component---src-pages-jupiterfire-js" */),
  "component---src-pages-kalabhiravafire-js": () => import("./../../../src/pages/kalabhiravafire.js" /* webpackChunkName: "component---src-pages-kalabhiravafire-js" */),
  "component---src-pages-ketufire-js": () => import("./../../../src/pages/ketufire.js" /* webpackChunkName: "component---src-pages-ketufire-js" */),
  "component---src-pages-kukutafire-js": () => import("./../../../src/pages/kukutafire.js" /* webpackChunkName: "component---src-pages-kukutafire-js" */),
  "component---src-pages-lakshmi-narayana-fire-js": () => import("./../../../src/pages/lakshmi-narayana-fire.js" /* webpackChunkName: "component---src-pages-lakshmi-narayana-fire-js" */),
  "component---src-pages-lakshmikubera-js": () => import("./../../../src/pages/lakshmikubera.js" /* webpackChunkName: "component---src-pages-lakshmikubera-js" */),
  "component---src-pages-mahalakshmifire-js": () => import("./../../../src/pages/mahalakshmifire.js" /* webpackChunkName: "component---src-pages-mahalakshmifire-js" */),
  "component---src-pages-mercuryfire-js": () => import("./../../../src/pages/mercuryfire.js" /* webpackChunkName: "component---src-pages-mercuryfire-js" */),
  "component---src-pages-moola-nakshatra-fire-js": () => import("./../../../src/pages/moola-nakshatra-fire.js" /* webpackChunkName: "component---src-pages-moola-nakshatra-fire-js" */),
  "component---src-pages-moonfire-js": () => import("./../../../src/pages/moonfire.js" /* webpackChunkName: "component---src-pages-moonfire-js" */),
  "component---src-pages-murugafire-js": () => import("./../../../src/pages/murugafire.js" /* webpackChunkName: "component---src-pages-murugafire-js" */),
  "component---src-pages-nadiastrology-js": () => import("./../../../src/pages/nadiastrology.js" /* webpackChunkName: "component---src-pages-nadiastrology-js" */),
  "component---src-pages-navagrahafire-js": () => import("./../../../src/pages/navagrahafire.js" /* webpackChunkName: "component---src-pages-navagrahafire-js" */),
  "component---src-pages-parvathy-ankara-fire-js": () => import("./../../../src/pages/parvathy-ankara-fire.js" /* webpackChunkName: "component---src-pages-parvathy-ankara-fire-js" */),
  "component---src-pages-parvathy-beauty-fire-js": () => import("./../../../src/pages/parvathy-beauty-fire.js" /* webpackChunkName: "component---src-pages-parvathy-beauty-fire-js" */),
  "component---src-pages-parvathyfire-js": () => import("./../../../src/pages/parvathyfire.js" /* webpackChunkName: "component---src-pages-parvathyfire-js" */),
  "component---src-pages-payonline-js": () => import("./../../../src/pages/payonline.js" /* webpackChunkName: "component---src-pages-payonline-js" */),
  "component---src-pages-rahufire-js": () => import("./../../../src/pages/rahufire.js" /* webpackChunkName: "component---src-pages-rahufire-js" */),
  "component---src-pages-rudrafire-js": () => import("./../../../src/pages/rudrafire.js" /* webpackChunkName: "component---src-pages-rudrafire-js" */),
  "component---src-pages-santhana-gopala-fire-js": () => import("./../../../src/pages/santhana-gopala-fire.js" /* webpackChunkName: "component---src-pages-santhana-gopala-fire-js" */),
  "component---src-pages-saraswathyfire-js": () => import("./../../../src/pages/saraswathyfire.js" /* webpackChunkName: "component---src-pages-saraswathyfire-js" */),
  "component---src-pages-saturnfire-js": () => import("./../../../src/pages/saturnfire.js" /* webpackChunkName: "component---src-pages-saturnfire-js" */),
  "component---src-pages-sharabafire-js": () => import("./../../../src/pages/sharabafire.js" /* webpackChunkName: "component---src-pages-sharabafire-js" */),
  "component---src-pages-sudharshanafire-js": () => import("./../../../src/pages/sudharshanafire.js" /* webpackChunkName: "component---src-pages-sudharshanafire-js" */),
  "component---src-pages-sunfire-js": () => import("./../../../src/pages/sunfire.js" /* webpackChunkName: "component---src-pages-sunfire-js" */),
  "component---src-pages-swayamvara-parvathy-fire-js": () => import("./../../../src/pages/swayamvara-parvathy-fire.js" /* webpackChunkName: "component---src-pages-swayamvara-parvathy-fire-js" */),
  "component---src-pages-templetrips-js": () => import("./../../../src/pages/templetrips.js" /* webpackChunkName: "component---src-pages-templetrips-js" */),
  "component---src-pages-uma-maheshwara-fire-js": () => import("./../../../src/pages/uma-maheshwara-fire.js" /* webpackChunkName: "component---src-pages-uma-maheshwara-fire-js" */),
  "component---src-pages-venusfire-js": () => import("./../../../src/pages/venusfire.js" /* webpackChunkName: "component---src-pages-venusfire-js" */)
}

